<template>
  <div>
    <el-form
      :model="linkInfo"
      :rules="rules"
      ref="payInfo"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="个人分组" prop="groupId">
        <el-select v-model="linkInfo.groupId" placeholder="请选择个人分组">
          <el-option
            v-for="item in groupIdRegion"
            :label="item.label"
            :value="item.value"
            :key="item.value"
            @click="chooseGroupId(item.value)"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="域名类型" prop="domainType">
        <el-select v-model="linkInfo.domainType" placeholder="请选择域名类型">
          <el-option
            v-for="item in domainTypeRegion"
            :label="item.label"
            :value="item.value"
            :key="item.value"
            @click="chooseDomainType(item.value)"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="官方域名" prop="officialDomainId">
        <el-select
          v-model="linkInfo.officialDomainId"
          placeholder="请选择短链域名"
          :disabled="officialDomainLock"
        >
          <el-option
            v-for="item in officialDomainIdRegion"
            :label="item.label"
            :value="item.value"
            :key="item.value"
            @click="chooseDomainId(item.value)"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="自定义域名" prop="customDomainId">
        <el-select
          v-model="linkInfo.customDomainId"
          placeholder="请选择短链域名"
          :disabled="customDomainLock"
        >
          <el-option
            v-for="item in customDomainIdRegion"
            :label="item.label"
            :value="item.value"
            :key="item.value"
            @click="chooseDomainId(item.value)"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="短链名称" prop="title">
        <el-input v-model="linkInfo.title" style="width: 213px" />
      </el-form-item>
      <el-form-item label="原始链接" prop="originalUrl">
        <el-input v-model="linkInfo.originalUrl" style="width: 213px" />
      </el-form-item>
      <el-form-item label="过期时间" prop="expired">
        <el-date-picker
          v-model="this.linkInfo.expiredDay"
          type="date"
          placeholder="请选择一个日期"
          style="width: 213px"
          @change="setExpiredDay"
        />
        <span>&ensp;-&ensp;</span>
        <el-time-picker
          v-model="this.linkInfo.expiredTime"
          arrow-control
          placeholder="请选择一个时间"
          style="width: 213px"
          @change="setExpiredTime"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="createLink(linkInfo)"
          v-loading.fullscreen.lock="fullscreenLoading"
          >创建短链
        </el-button>
        <el-button @click="createLink(linkInfo)">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      linkInfo: {
        groupId: "",
        title: "",
        originalUrl: "",
        domainId: "",
        domainType: "",
        expiredDay: "",
        expiredTime: "",
        setDay: "",
        setTime: "",
        expired: "",
        customDomainId: "",
        officialDomainId: "",
      },
      rules: {
        groupId: [
          { required: true, message: "请选择个人分组", trigger: "blur" },
        ],
        domainType: [
          { required: true, message: "请选择域名类型", trigger: "blur" },
        ],
        officialDomainId: [
          { required: true, message: "请选择短链域名", trigger: "blur" },
        ],
        customDomainId: [
          { required: true, message: "请选择短链域名", trigger: "blur" },
        ],
        title: [
          {
            required: true,
            message: "请输入待创建的短链名称",
            trigger: "blur",
          },
        ],
        originalUrl: [
          { required: true, message: "请输入原始链接", trigger: "blur" },
        ],
        expired: [
          { required: false, message: "请选择一个日期", trigger: "blur" },
        ],
      },
      groupIdRegion: [
        {
          label: "---请选择个人分组---",
          value: "",
        },
        {
          label: "淘宝分组",
          value: "1504080834830737410",
        },
        {
          label: "拼多多分组",
          value: "1501474216192466945",
        },
      ],
      domainTypeRegion: [
        {
          label: "---请选择域名类型---",
          value: "",
        },
        {
          label: "官方域名",
          value: "OFFICIAL",
        },
        {
          label: "自定义域名",
          value: "CUSTOM",
        },
      ],
      officialDomainIdRegion: [
        {
          label: "---请选择短链域名---",
          value: "",
        },
        {
          label: "g1.linkcl.top",
          value: "1504052618728456194",
        },
        {
          label: "g2.linkcl.top",
          value: "1504052685896040450",
        },
      ],
      customDomainIdRegion: [
        {
          label: "---请选择短链域名---",
          value: "",
        },
        {
          label: "cl.top",
          value: "1504052746407264257",
        },
        {
          label: "cl2.top",
          value: "1504083265962582017",
        },
        {
          label: "cl3.top",
          value: "1517092875967283202",
        },
      ],
      fullscreenLoading: false,
      customDomainLock: true,
      officialDomainLock: true,
    };
  },
  methods: {
    createLink(linkInfo) {
      this.$store.dispatch("createLink", linkInfo);
      this.fullscreenLoading = true;
      setTimeout(() => {
        this.fullscreenLoading = false;
        this.$router.push({ name: "myLink" });
      }, 2000);
    },
    chooseGroupId(groupId) {
      if (groupId !== "") {
        this.linkInfo.groupId = groupId;
      }
    },
    chooseDomainType(domainType) {
      this.customDomainLock = false;
      this.officialDomainLock = false;
      if (domainType === "") {
        this.customDomainLock = true;
        this.officialDomainLock = true;
      } else if (domainType === "OFFICIAL") {
        this.linkInfo.domainType = domainType;
        this.customDomainLock = true;
        this.officialDomainLock = false;
      } else if (domainType === "CUSTOM") {
        this.linkInfo.domainType = domainType;
        this.customDomainLock = false;
        this.officialDomainLock = true;
      }
    },
    chooseDomainId(domainId) {
      if (domainId !== "") {
        this.linkInfo.domainId = domainId;
      }
    },
    setExpiredDay() {
      let temp = this.linkInfo.expiredDay.toString().split(" ");
      let month = "0";
      switch (temp[1]) {
        case "Jan":
          month = "01";
          break;
        case "Feb":
          month = "02";
          break;
        case "Mar":
          month = "03";
          break;
        case "Apr":
          month = "04";
          break;
        case "May":
          month = "05";
          break;
        case "Jun":
          month = "06";
          break;
        case "Jul":
          month = "07";
          break;
        case "Aug":
          month = "08";
          break;
        case "Sep":
          month = "09";
          break;
        case "Oct":
          month = "10";
          break;
        case "Nov":
          month = "11";
          break;
        case "Dec":
          month = "12";
          break;
      }
      this.linkInfo.setDay = temp[3] + "-" + month + "-" + temp[2] + " ";
    },
    setExpiredTime() {
      let temp = this.linkInfo.expiredTime.toString().split(" ");
      this.linkInfo.setTime = temp[4];
    },
  },
};
</script>

<style scoped></style>
